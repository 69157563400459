import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import './index.scss';

function getModalStyle() {
    return {
        top: `100px`,
        left: `5%`,
        textAlign: 'center'
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
});

class SimpleModalLocal extends React.Component {
    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);
    }

    state = {
        open: false,
    };

    handleOpen() {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const videoURL = 'https://www.meritmile.com/uploads/finastra-social.mp4';
        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                    className="videoModal"
                >
                    <div style={getModalStyle()} className={classes.paper+' modalBox'}>
                        <video controls style={{ width: `85%`, maxWidth: `85`, margin: '0 auto' }}>
                            <source src={videoURL} type="video/mp4" />
                        </video>
                    </div>
                </Modal>
            </div>
        );
    }
}

SimpleModalLocal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleModalLocal);