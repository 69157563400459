import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModalLocal from '../components/utilities/SimpleModalLocal';

import "./casestudyfinastramicrosoft.scss"

import imgQuote from "../images/quotation_mark.webp"

class LocalVideo extends Component {
  constructor(props) {
      super(props);
      this.modalRef = React.createRef();
      this.openModal = this.openModal.bind(this);
  };
  openModal() {
      this.modalRef.current.handleOpen();
  };
    render() {
        return (
            <>
                <img
                    src="https://www.meritmile.com/uploads/2025/03/finastra-results.webp"
                    className="results-image img-fluid"
                    alt="Finastra Microsoft Results"
                    width="1400px"
                    style={{ marginLeft: '-10%', marginBottom: '2em', maxWidth: '125%' }}
                    onClick={() => this.openModal()} id="playVideo"
                />
                <SimpleModalLocal videoSRC='finastra-social.mp4' innerRef={this.modalRef} />
            </>
        );
    }
}

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-fm-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 2.5}
                            height={pageContext.acf.client_logo.media_details.height / 2.5}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4" style={{ display: "none" }}>
                        <a href="https://www.meritmile.com/uploads/2022/11/microsoft-and-nuance-lead-generation-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div
                            className="first-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.first_paragraph,
                            }}
                        />
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2025/03/finastra-challenge.webp"
                            className="results-image img-fluid"
                            alt="Finastra Microsoft Challenge"
                            width="858px"
                            style={{ marginBottom: '2em', marginLeft: '-4em' }}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <h3>Challenge</h3>
                        <p>Finastra and Microsoft had a shared business objective of developing high-value qualified leads to accelerate their co-sell pipeline. The challenge was consistently delivering qualified leads, targeting very specific organizations and roles within the financial services, banking, and payments sectors in North America and Europe. Given the highly competitive nature of the Payments-as-a-Service space, Finastra needed an Account Based Marketing (ABM) agency partner to help them compete, build a robust pipeline, and improve their overall marketplace position and level of awareness.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <p>When Finastra first approached Merit Mile, they had been working with Omdia and Datos Insights to develop marketing research reports, infographics, and webinars. Now they needed to “put these assets to work” and convert content into campaigns and leads, which is where Merit Mile came in.</p>
                        <p>Microsoft introduced Finastra to Merit Mile, a full-service marketing agency with experience in Microsoft partner marketing and ABM. Finastra valued Merit Mile's comprehensive ABM services, covering strategy, media buys, content development, execution, and reporting, allowing their marketing team to focus on lead nurturing and product marketing.</p>
                    </div>
                </div>
              </div>
              <div className="container detail-wrapper">
                <div className="row margin-b">
                    <div className="col-12">
                        <h3>Solution</h3>
                        <p>Merit Mile initially met with Finastra to understand their business objectives for the marketing scorecard and sales pipeline. We gathered information about their target companies and buying teams (decision makers and influencers) to create an ideal customer profile (ICP), which was essential for determining the appropriate message, tone, and content for the campaign. Subsequently, Merit Mile developed an ABM campaign and content strategy aligned with Finastra’s goals, which included selecting ABM tactics and platforms.</p>
                        <p>At the same time, Merit Mile identified the need for additional content offers to augment the analyst reports. For this, we developed a series of videos and an original piece of thought leadership content for the campaign, 6 Essential Business Requirements to look for in a Payments as a Service Cloud Solution, which provided in-depth insights into the critical elements necessary for successful Payments-as-a-Service implementations.</p>
                    </div>
                </div>
              </div>
              <div className="container detail-wrapper">
                <div className="row margin-b">
                  <div className="col-12">
                      <p>For the initial ABM campaign, Merit Mile used a two-touch ABM campaign, which means prospects need to engage with at least two interactions with Finastra's content before being converted to a qualified and accepted lead. In addition, prospects were asked a series of qualifying questions via email and telephone, which armed Finastra's inside sales team with account insights and intelligence to improve their post-lead delivery nurture process.</p>
                      <p>The first campaign was so successful that Finastra elected to continue with two additional ABM campaigns to generate more leads and retarget leads from the initial campaign, encouraging them to engage with additional down-funnel content from Finastra.</p>
                  </div>
                </div>
              </div>
              <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <h3>Results</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <p>Merit Mile ran a total of three consecutive ABM campaigns over the course of 14 weeks, demonstrating not only consistent results, but more importantly, continuous marketplace awareness and pipeline development for Finastra.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 col-md-6">
                      <p>The first and second ABM campaigns were focused on initial marketing pipeline development, developing new qualified leads against the target account list. In contrast, for the third campaign, Merit Mile recommended a mix of retargeting existing leads (from campaign one and two) to further nurture them with new Finastra analyst reports and webinars as well as net-new lead development against an updated target account list.</p>
                      <p>Throughout the campaign, Merit Mile performed weekly quality control checks on the leads, making sure they met the target criteria, and built live campaign dashboards that showed performance metrics, analytics, and useful insights.</p>
                      <p>The campaigns demonstrated significant account audience penetration against Finastra's ICP, with 42% of leads at the C-level, 24% at the AVP/EVP/SVP/VP level, and 34% at the Director/Head level.</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <img
                            src="https://www.meritmile.com/uploads/2025/03/finastra-leadership-account-level-penetration.webp"
                            className="results-image img-fluid"
                            alt="Finastra Microsoft Leadership Account Level Penetration"
                            width="501px"
                            style={{ marginBottom: '2em' }}
                        />
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                      <p>These results highlight the efficacy of the ABM strategy in generating high-quality leads and building a robust co-sell pipeline for Finastra and Microsoft. The campaign's success underscores the importance of using a trusted and proven ABM agency to deliver personalized, targeted marketing that achieves measurable business outcomes.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 text-center">
                        <LocalVideo />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                      <h3>Merit Mile Account Based Marketing Services</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 col-md-6">                        
                        <ul>
                            <li>Marketing and campaign strategy</li>
                            <li>Media buy and vendor management</li>
                            <li>Campaign execution and optimization</li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6">                        
                        <ul>
                            <li>Campaign reporting and analytics</li>
                            <li>Content development and creative design</li>
                            <li>Video production</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="fullWidthGrayCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h5>Learn more about how Merit Mile can support your vertical content marketing, lead generation, and sales goals by contacting <a href="mailto:Hello@MeritMile.com?subject=Finastra%20Microsoft%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>hello@meritmile.com.</b></a></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="yourteam-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Your Merit Mile Team</h3>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-12">
                            <div className="team-cards">
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sl-thumb.jpg"
                                        alt="Scott Lindars"
                                        className="img-fluid"
                                    />
                                    <h4>Scott Lindars</h4>
                                    <p>
                                        Director<br />
                                        Marketing +<br />
                                        Business Strategy
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sd-thumb.jpg"
                                        alt="Saul Darville"
                                        className="img-fluid"
                                    />
                                    <h4>Saul Darville</h4>
                                    <p>
                                        Executive<br />
                                        Creative<br />
                                        Director
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/mc-thumb.jpg"
                                        alt="Mitch Curry"
                                        className="img-fluid"
                                    />
                                    <h4>Mitch Curry</h4>
                                    <p>
                                        Project<br />
                                        Manager<br />
                                        &nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=Teldoc%20Health%20Microsoft%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
